import React, { Fragment, useState } from 'react'
import { recaptchaSiteKey } from '../utils/Constants';

import './Contact.css';
import ContactForm from '../components/ContactForm'
import firebaseClient from '../firebase/FirebaseClient';
import OverlayText from '../components/OverlayText'


const Contact = () => {
    
    const [formData, setFormData] = useState({
        email: '',
        enquiryType: '',
        message: ''
    });
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [statusMessage, setStatusMessage] = useState('');
    const [statusType, setStatusType] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [contentText, setContentText] = useState("Processing...")

    const handleChange = (e) => {
        setFormData({
        ...formData,
        [e.target.name]: e.target.value
        });
    };

    const resetForm = () => {
        setFormData(
            { 
                email: "",
                enquiryType: "",
                message: ""
            }
        )
        setStatusMessage("")
        setStatusType("")
    }

    const resetStatus = () => {
        setStatusMessage("")
        setStatusType("")
    }

    const sendMessage = async (e) =>  {
        e.preventDefault();
        //setIsLoading(true)
        
        //if (!recaptchaToken) {
            //alert('Please complete the reCAPTCHA.');
        //    setStatusMessage('Please complete the reCAPTCHA.');
        //    setStatusType('error');
        //    return;
        //} else if (!formData.email) {
        if (!formData.email) {
            //alert('Please enter your email.');
            setStatusMessage('Please enter your email.');
            setStatusType('error');
            return;
        } else if (!formData.enquiryType) {
            //alert('Please choose a request type.');
            setStatusMessage('Please choose a enquiry type.');
            setStatusType('error');
            return;
        } else if (formData.message === "") {
            setStatusMessage('Please enter the message.');
            setStatusType('error');
            return
        } 

        setIsLoading(true)
    
        console.log(`Form data - email: ${formData.email}`)
        console.log(`Form data - enquiry type: ${formData.enquiryType}`)
        console.log(`Form data - message: ${formData.message}`)

        const { success, message } = await firebaseClient.sendMessage(
            formData.email, 
            formData.enquiryType,
            formData.message,
            ""
        )

        if (success) {
            setStatusType("success")
        } else {
            setStatusType("error")
        }
        setStatusMessage(message)

        console.log(`result: success? ${success}, message: ${message}`)

        setIsLoading(false)

    }

    return ( 
        <Fragment>
            <div className="contact-page">
            {isLoading && <div className='contact-form-overlay'><OverlayText content={contentText} /></div>}
                <div className='contact-page-title'>
                    Contact Us
                </div>
                    <ContactForm 
                        formData={formData}
                        handleChange={handleChange}
                        recaptchaSiteKey={recaptchaSiteKey}
                        setRecaptchaToken={setRecaptchaToken}
                        statusType={statusType}
                        statusMessage={statusMessage}
                        resetForm={resetForm}
                        resetStatus={resetStatus}
                        onSend={sendMessage}
                        className="contact-page-form"
                    />
            </div>
        </Fragment>
    )
}

export default Contact;

/*
const [activeTab, setActiveTab] = useState('app'); // 'app' or 'general'
const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
<div className='contact-page-banner'>
    <div 
        className={`contact-page-banner-tab ${activeTab === 'app' ? 'active' : ''}`} 
        onClick={() => handleTabClick('app')}
    >
        App Enquiries
    </div>
    <div 
        className={`contact-page-banner-tab ${activeTab === 'general' ? 'active' : ''}`} 
        onClick={() => handleTabClick('general')}
    >
        General Enquiries
    </div>
</div>
*/