
import backArrow from '../images/before.png';
import nextArrow from '../images/next.png';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import "./Arrows.css"

export const BackArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div 
            className={`${className} navigate-arrow back`}
            onClick={onClick}
            style={{ ...style }}
            >
                <FaArrowLeft />
        </div>
    );
};

export const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div 
            className={`${className} navigate-arrow next`}
            onClick={onClick}
            style={{ ...style }}
            >
                <FaArrowRight />
        </div>
    );
};

/*
export const BackArrow = (props) => {
    const { className, onClick } = props;
    return (
        <img 
            className={`${className} navigate-arrow back`} 
            src={backArrow} 
            alt="Go back" 
            onClick={onClick}
        />
  
    );
};

export const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <img 
            className={`${className} navigate-arrow next`} 
            src={nextArrow} 
            alt="Go forward" 
            onClick={onClick}
        />
    );
};

className={`${className} navigate-arrow back`}
className={`${className} navigate-arrow next`}

*/