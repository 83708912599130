
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc  } from "firebase/firestore";
import { firebaseConfig } from './FirebaseConfig';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

class FirebaseClient {
    constructor() {
      const app = initializeApp(firebaseConfig);
      this.db = getFirestore(app);
      const auth = getAuth(app);
      this.auth = auth;
      this.functions = getFunctions(app)
    }
  
    async sendRequest(email, requestType, additionalInfo, recaptchaToken) {
      const sendDeletionRequest = httpsCallable(this.functions, 'sendDeletionRequest');

      try {
        const response = await sendDeletionRequest({ email, requestType, additionalInfo, recaptchaToken });
        //alert(result.data.message);
        const result = {
          success: response.data.success,
          message: response.data.message
        }
        return result
      } catch (error) {
        //alert('Error: ' + error.message);
        const result = {
          success: false,
          message: "web client side error"
        }
        console.error("Error sending request: ", error);
        return result
      }
    }

    async sendMessage(email, enquiryType, message, recaptchaToken) {
      const sendMessageOver = httpsCallable(this.functions, 'sendMessageOver');
      try {
        const response = await sendMessageOver({ email, enquiryType, message, recaptchaToken });
        //alert(result.data.message);
        const result = {
          success: response.data.success,
          message: response.data.message
        }
        return result
      } catch (error) {
        //alert('Error: ' + error.message);
        console.error("Error sending message: ", error);
        const result = {
          success: false,
          message: "web client side error"
        }
        return result
      }
    }

    async signInAnonymously(email, password) {
      try {
        const userCredential = await signInAnonymously(this.auth);
        console.log('User signed in anonymously');
      } catch (e) {
        console.error("Error signing in: ", e);
      }
    }
  }
  
  const firebaseClient = new FirebaseClient();
  export default firebaseClient;
/*

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

async function sendRequest(db, email, requestType, additionalInfo) {
    try {
        const docRef = await addDoc(collection(db, "userDeleteRequests"), {
          email: email,
          requestType: requestType,
          additionalInfo: additionalInfo
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    
}

  const querySnapshot = await getDocs(collection(db, "userDeleteRequests"));
  //return querySnapshot.docs.map((doc) => doc.data());
  collection(db, "userDeleteRequests").add({
    email: "

}
    
        const docRef = await addDoc(collection(this.db, "userDeleteRequests"), {
          email: email,
          requestType: requestType,
          additionalInfo: additionalInfo
        });
        
        
        //console.log("Document written with ID: ", docRef.id);
   */