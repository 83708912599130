
import ReCAPTCHA from 'react-google-recaptcha';

import './ContactForm.css'

const ContactForm = ({formData, 
    handleChange,
    recaptchaSiteKey,
    setRecaptchaToken,
    statusType,
    statusMessage,
    resetForm,
    resetStatus,
    onSend, 
    className,
}) => {
    
    return (
        <form 
            className={`contact-form ${className}`} 
            onSubmit={onSend}
        >
            <div className='contact-form-container'>
            <div></div>
            
                <div className="enquiry-options">
                    <select
                        id="enquiry"
                        name="enquiryType"
                        value={formData.enquiryType}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select an enquiry type</option>
                        <option value="reportAnIssue">App Issues</option>
                        <option value="generalEnquiry">General Enquiries</option>
                        
                    </select>
                </div>
                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                ></input>
                
                <label htmlFor="message">Message</label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="4"
                />
                <div>
                </div>
                <div className='contact-form-status-message-container'>
                    <div className={`contact-form-status-message ${statusType ? statusType : 'no-status'}`}>
                        {statusMessage &&  (
                            <>
                                <span >{statusMessage}</span>
                                <button 
                                    className={`contact-form-ok-button ${statusType}`}
                                    onClick={ statusType == "success" ? resetForm : resetStatus }
                                    
                                >OK</button>
                            </>
                        )}
                    </div>
                </div>
                <button className="send-button" onClick={onSend}>
                    Send
                </button>
            </div>
        </form>
    )
}

export default ContactForm;

/*
<div className="contact-form-recaptcha">
                    <ReCAPTCHA
                        sitekey={recaptchaSiteKey}
                        onChange={(token) => setRecaptchaToken(token)} 
                    />

*/