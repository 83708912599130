
import React from 'react';
import './OverlayText.css'; // Make sure the CSS file is correctly imported

const Overlay = ({content}) => {
  return (
      <div className="overlay-content">
        <p>{content}</p>
      </div>
  );
};

export default Overlay;