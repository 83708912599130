

import './SubmitButton.css'

const SubmitButton = ({ isLoading, isDone, buttonText }) => {
    //const [buttonText, setButtonText] = useState('Submit Request');
    //const [isLoading, setIsLoading] = useState(false);
    //const [isDone, setIsDone] = useState(false);
  
    //const handleSubmit = async () => {
    //  setIsLoading(true);
     // setButtonText('');
      /*
      try {
        const response = await axios.get(`https://<your-region>-<your-project-id>.cloudfunctions.net/confirmDeletionRequest?token=${token}`);
        if (response.status === 200) {
          setIsDone(true);
          setButtonText('Sent');
        } else {
          setButtonText('Failed');
        }
      } catch (error) {
        console.error('Error submitting request:', error);
        setButtonText('Error submitting request');
      } finally {
        setIsLoading(false);
      }
    };
    */
  
    return (
      <button 
        className="submit-button"
        disabled={isLoading || isDone} 
        type="submit"
      >
        {buttonText}
      </button>
    );
  };
  
  export default SubmitButton;

  // style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer', display: 'inline-block', textAlign: 'center' }}