

import React, { Fragment } from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import HomePage from './pages/HomePage';
import PrivatePolicy from './pages/PrivatePolicy';
import DataPrivacy from './pages/DataPrivacy';
import Contact from './pages/Contact';
import NavBar from './components/NavBar';

import './App.css';

function App() {
  return (
    <Router>
      <div className='App background-container'>
        <div id="root">
          <NavBar />
          <div className='app-content'>
            <Routes>
              <Route path='/' exact element={<HomePage />}/>
              <Route path='/privacy-policy' exact element={<PrivatePolicy />}/>
              <Route path='/data-privacy' exact element={<DataPrivacy />}/>
              <Route path='/contact' exact element={<Contact />}/>
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
