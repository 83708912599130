import React, { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'

import './NavBar.css';


const NavBar = () => {
    const location = useLocation()

    return (
            <nav className="navbar">
                <div className="navbar-container">
                    <NavItem to="/" label="Home" currentPath={location.pathname}/>
                    <NavItem to="/privacy-policy" label="Privacy Policy" currentPath={location.pathname}/>
                    <NavItem to="/data-privacy" label="Data Privacy" currentPath={location.pathname} />
                    <NavItem to="/contact" label="Contact" currentPath={location.pathname} />
                </div>
            </nav>
    )
}

export default NavBar;

const NavItem = ({ to, label, currentPath }) => {
    const isActive = currentPath === to; // Check if the current path matches the link's path

    return (
        <Link to={to} className={`nav-link ${isActive ? 'active-link' : ''}`}>{label}</Link>
    );
}
